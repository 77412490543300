/* eslint-disable no-mixed-spaces-and-tabs */
const validatePhoneNumber = ( phone ) => {
	const regex = /^(\+38)?\s?\(?\d{3}\)?\s?\d{3}\s?\d{2}\s?\d{2}$/;
	return regex.test( phone );
};

function initAOS() {
	AOS.init({
		initClassName: 'aos-init',
		once: true
	});
}

var isActive = false;
var currentIdService = [];


const dataCities = [
	{
		'city': 'Київ та область',
		'services': [
			{
				'id': 1,
				'name': 'УКРАВТО ГОЛОСІЇВСЬКИЙ',
				phone: '380731230037',
				'address': 'м. Київ, вул. Героїв Оборони, 4'
			},
			{
				'id': 2,
				'name': 'УКРАВТО НА КІЛЬЦЕВІЙ',
				phone: '380731230038',
				'address': 'с. Софіївська Борщагівка, вул. Толстого, 2'
			},
			{
				'id': 3,
				'name': 'УКРАВТО КИЇВ (ПЕТРІВКА)',
				phone: '380731230041',
				'address': 'м. Київ, пр-т. Степана Бандери, 22'
			},
			{
				'id': 4,
				'name': 'УКРАВТО НА СТОЛИЧНОМУ',
				phone: '380731230043',
				'address': 'м. Київ, Столичне шосе, 90'
			},
			{
				'id': 5,
				'name': 'УКРАВТО НА ТЕРЕМКАХ',
				phone: '380733037050',
				'address': 'м. Київ, пр-т. Академіка Глушкова, 67'
			},
			{
				'id': 6,
				'name': 'УКРАВТО БЛІЦ (ЛІСОВА)',
				phone: '044 502-88-06',
				'address': 'м. Київ, вул. Мурманська, 6'
			},
			{
				'id': 7,
				'name': 'УКРАВТО ЧАЙКА',
				phone: '380733037047',
				'address': 'м. Київ, 14-й км Житомирського шосе'
			},
			{
				'id': 8,
				'name': 'УКРАВТО НА БОРЩАГІВЦІ',
				phone: '0442903442',
				'address': 'с. Петропавлівська Борщагівка, вул. Велика Кільцева, 2'
			},
			{
				'id': 9,
				'name': 'УКРАВТО БІЛА ЦЕРКВА',
				phone: '0800330065',
				'address': 'м. Біла Церква, вул. Сухоярська, 20'
			},

			{
				'id': 9,
				'name': 'УКРАВТО НА ХАРКІВСЬКОМУ',
				phone: '380675502911',
				'address': 'м. Київ, Харківське Шосе, 179',
			},			{
				'id': 9,
				'name': 'УКРАВТО МРІЯ',
				phone: '0444980008',
				'address': 'м. Київ, вул. Туполєва, 19'
			},
			{
				'id': 9,
				'name': 'УКРАВТО БРОВАРИ',
				phone: '380675500316',
				'address': 'м. Бровари, вул. Броварської сотні, 7Б'
			}
		]
	},
	{
		'city': 'Вінниця',
		'services': [
			{
				'id': 10,
				'name': 'УКРАВТО ВІННИЦЯ',
				phone: '380733037011',
				'address': 'м. Вінниця, вул. Ватутіна, 172'
			}
		]
	},
	{
		'city': 'Луцьк',
		'services': [
			{
				'id': 11,
				'name': 'УКРАВТО ВОЛИНЬ',
				phone: '380731230026',
				'address': 'м. Луцьк, вул. Рівненська, 145'
			}
		]
	},
	{
		'city': 'Дніпро та область',
		'services': [
			{
				'id': 12,
				'name': 'УКРАВТО ДНІПРО',
				phone: '380731230027',
				'address': 'м. Дніпро, пр-т. Праці, 16'
			},
			{
				'id': 121,
				'name': 'УКРАВТО ПАВЛОГРАД',
				phone: '380675508937',
				'address': 'м. Павлоград, вул.Дніпровська, 334 А'
			},
			{
				'id': 122,
				'name': ' УКРАВТО КРИВИЙ РІГ',
				phone: '380675508931',
				'address': 'м. Кривий Ріг, вул. Генерала Радієвського, 34 А'
			}
		]
	},
	{
		'city': 'Житомир',
		'services': [
			{
				'id': 13,
				'name': 'УКРАВТО ЖИТОМИР',
				phone: '380733037010',
				'address': 'м. Довжик, вул. Богунська, 1 А'
			}
		]
	},
	{
		'city': 'Ужгород',
		'services': [
			{
				'id': 14,
				'name': 'УКРАВТО ЗАКАРПАТТЯ',
				phone: '380731230028',
				'address': 'м. Ужгород, вул. Олександра Блистіва, 20'
			}
		]
	},
	{
		'city': 'Запоріжжя',
		'services': [
			{
				'id': 15,
				'name': 'УКРАВТО ЗАПОРІЖЖЯ',
				phone: '380733037020',
				'address': 'м. Запоріжжя, вул. Шенвізька, 2а'
			}
		]
	},
	{
		'city': 'Івано-Франківськ',
		'services': [
			{
				'id': 16,
				'name': 'УКРАВТО ІВАНО-ФРАНКІВСЬК',
				phone: '380733037034',
				'address': 'м. Івано-Франківськ, вул. Тисменицька, 210'
			}
		]
	},
	{
		'city': 'Кропивницький',
		'services': [
			{
				'id': 16,
				'name': 'УКРАВТО КРОПИВНИЦЬКИЙ',
				phone: '380733037038',
				'address': 'м. Кропивницький , вул. Родімцева, 123'
			}
		]
	},
	{
		'city': 'Львів та область',
		'services': [
			{
				'id': 18,
				'name': 'УКРАВТО ЛЬВІВ',
				phone: '+38 (032) 232-07-69',
				'address': 'м. Львів, вул. Городоцька, 282'
			},
			{
				'id': 18,
				'name': 'УКРАВТО РАДАР',
				phone: '068 0 118 118',
				'address': 'с. Сокільники, вул. Стрийська, 350'
			}
		]
	},
	{
		'city': 'Одеса',
		'services': [
			{
				'id': 19,
				'name': 'УКРАВТО ОДЕСА',
				phone: '380731230031',
				'address': 'м. Одеса, Київське шосе, 27 А'
			}
		]
	},
	{
		'city': 'Полтава та область',
		'services': [
			{
				'name': 'УКРАВТО ПОЛТАВА',
				phone: '380733037012',
				'address': 'м. Полтава, вул. Великотирновська, 1',
				'id': 223
			},
			{
				'name': 'УКРАВТО КРЕМЕНЧУК',
				phone: '380733037013',
				'address': 'м. Кременчук, вул. Московська, 10',
				'id': 223
			}
		]
	},
	{
		'city': 'Суми',
		'services': [
			{
				'name': 'УКРАВТО СУМИ',
				phone: '380733037017',
				'address': 'м. Суми, вул. Харківська, 77/1',
				'id': 223
			}
		]
	},
	{
		'city': 'Тернопіль',
		'services': [
			{
				'name': 'УКРАВТО ТЕРНОПІЛЬ',
				phone: '380733037039',
				'address': 'м. Тернопіль, вул. Львівська, 12',
				'id': 22
			}
		]
	},
	{
		'city': 'Хмельницький',
		'services': [
			{
				'name': 'УКРАВТО ХМЕЛЬНИЦЬКИЙ',
				phone: '380731230025',
				'address': 'м. Хмельницький, вул. Подільська, 97',
				'id': 24
			}
		]
	},
	{
		'city': 'Рівне',
		'services': [
			{
				'name': 'УКРАВТО РІВНЕ',
				phone: '0685500870',
				'address': 'м. Рівне, вул. Відінська, 9 A',
				'id': 24
			}
		]
	},
	{
		'city': 'Харків',
		'services': [
			{
				'name': 'УКРАВТО ХАРКІВ',
				phone: '067-550-86-70',
				'address': 'м. Харків, смт. Пісочин, вул.Надії, 15',
				'id': 24
			}
		]
	},
	{
		'city': 'Черкаси',
		'services': [
			{
				'name': 'УКРАВТО ЧЕРКАСИ',
				phone: '380733037024',
				'address': 'м. Черкаси, вул. Козацька, 12',
				'id': 25
			}
		]
	},
	{
		'city': 'Чернігів',
		'services': [
			{
				'name': 'УКРАВТО ЧЕРНІГІВ',
				phone: '380733037028',
				'address': 'м. Чернігів, вул. 1 Травня, 90',
				'id': 26
			}
		]
	},

	{
		'city': 'Чернівці',
		'services': [
			{
				'name': 'УКРАВТО БУКОВИНА',
				'phone': '380733037045',
				'address': 'м. Чернівці, вул. Коломийська, 15 А',
				'id': 26
			}
		]
	}
];


dataCities.sort( ( a, b ) => {
	if ( a.city < b.city ) {
		return -1;
	}
	if ( a.city > b.city ) {
		return 1;
	}
	return 0;
});


$( document ).ready( function() {
	function renderCities( id ) {
		const cityDropdown = document.getElementById( id );
		cityDropdown.innerHTML = '';
		dataCities.sort( ( a, b ) => {
			if ( a.city < b.city ) {
				return -1;
			}
			if ( a.city > b.city ) {
				return 1;
			}
			return 0;
		});
		dataCities.sort( ( a, b ) => a.city.localeCompare( b.city, 'uk' ) ).forEach( ( item, index ) => {
			const cityOption = document.createElement( 'span' );
			cityOption.textContent = item.city;
			cityOption.setAttribute( 'data-id', index );
			cityOption.onclick = () => selectCity( index );
			cityDropdown.appendChild( cityOption );
		});
	}

	renderCities( 'cityDropdown' );
	try {
		renderCities( 'cityDropdown2' );
	} catch ( er ) {
	}
	function selectCity( cityIndex ) {
		$( '.modal.active' ).find( '.dropdown-select-city' ).parent().parent().removeClass( 'show' );
		const selectedCity = dataCities[ cityIndex ];
		$( '.modal.active' ).find( '.dropdown-select-city' )[ 0 ].value = selectedCity.city;
		renderServices( selectedCity.services, 'serviceDropdown' );
		$( '.modal.active' ).find( '.cityDropdown-content' ).removeClass( 'show' );
		$( '.modal.active' ).find( '.field-dropdown.service' ).show();
	}

	function renderServices( services ) {
		$( '.modal.active' ).find( '.dropdown-select-service' )[ 0 ].value = '';

		const serviceDropdown = $( '.modal.active' ).find( '.service-content' )[ 0 ];
		serviceDropdown.innerHTML = '';

		if ( services.length === 1 ) {
			selectService( services[ 0 ] );
		} else {
			$('.modal-bottom-phone').css('display', 'none');
		}

		services.sort( ( a, b ) => a.name.localeCompare( b.name, 'uk' ) )
			.forEach( service => {
				const serviceOption = document.createElement( 'span' );
				serviceOption.textContent = service.name + ', ' + service.address;
				serviceOption.onclick = () => selectService( service );
				serviceDropdown.appendChild( serviceOption );
			});
	}

	function selectService( service, id ) {
		$( '.dropdown-select-service' ).parent().parent().removeClass( 'show' );
		$( '.modal.active' ).find( '.dropdown-select-service' )[ 0 ].value = service.name + ', ' + service.address;
		$( '.modal.active' ).find( '.service-content' )[ 0 ].classList.remove( 'show' );
		currentIdService = service.name;

		function formatPhoneNumber(phoneNumber) {
			// Удаляем все нецифровые символы
			phoneNumber = phoneNumber.replace(/\D/g, '');
		
			// Проверяем, если номер начинается с '38', добавляем '+'
			if (phoneNumber.startsWith('38')) {
				return `+${phoneNumber.slice(0, 2)} (${phoneNumber.slice(2, 5)}) ${phoneNumber.slice(5, 7)}-${phoneNumber.slice(7, 9)}-${phoneNumber.slice(9)}`;
			} 
			// Если номер начинается с '044' (Киев), добавляем '+38' перед номером
			else if (phoneNumber.startsWith('044')) {
				return `+38 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 5)}-${phoneNumber.slice(5, 7)}-${phoneNumber.slice(7)}`;
			} 
			// Для других номеров, добавляем '+38' и обрабатываем как обычный номер
			else {
				return `+38 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 5)}-${phoneNumber.slice(5, 7)}-${phoneNumber.slice(7)}`;
			}
		}		
		

		if ($(".modal.active").attr('data-modal') === 'phone') {
			$('.modal-bottom-phone').find('.phone span').html(formatPhoneNumber(service.phone))
			$('.modal-bottom-phone').find('.button-red').attr('href', `tel:${service.phone}`);
			$('.modal-bottom-phone').find('.phone-global').attr('href', `tel:${service.phone}`);
			$('.modal-bottom-phone').css('display', 'flex');
		}
	}

	$( '.dropdown-open' ).click( function( e ) {
		if ( $( this ).parent().hasClass( 'show' ) === true ) {

			// close

			$( '.dropdown-content' ).removeClass( 'show' );
			$( '.dropdown-select-city' ).parent().parent().removeClass( 'show' );
			$( '.dropdown-select-service' ).parent().parent().removeClass( 'show' );

		} else {
			$( '.modal.active' ).find( '.dropdown-content' ).removeClass( 'show' );
			$( '.modal.active' ).find( '.dropdown-select-city' ).parent().parent().removeClass( 'show' );
			$( '.modal.active' ).find( '.dropdown-select-service' ).parent().parent().removeClass( 'show' );

			$( this ).parent().addClass( 'show' );
			$( '.modal.active' ).find( '.dropdown-select-city' ).parent().find( '.show' ).removeClass( 'show' );
			$( '.modal.active' ).find( '.dropdown-select-service' ).parent().find( '.show' ).removeClass( 'show' );
			e.stopPropagation(); // Зупиняємо подальшу обробку події
			isActive = true;
			$( this ).parent().find( '.dropdown-content' ).addClass( 'show' );

			const offset = $( this ).offset();
			const dropdown = $( this ).siblings( '.dropdown-content' );

			const openDropdown = $( this ); // Елемент, який відкриває дропдаун
			const modalContainer = $( '.modal.active' ).find( '.modal-container' ); // Отримуємо контейнер модалки
			const modalOffset = modalContainer.offset(); // Отримуємо координати контейнера
			const dropdownHeight = dropdown.outerHeight(); // Высота дропдауна

			const windowHeight = $( window ).height(); // Высота окна
			const openDropdownOffset = openDropdown.offset(); // Позиция открывающего элемента
			const spaceBelow = windowHeight - openDropdownOffset.top - openDropdown.outerHeight(); // Доступное пространство снизу
			dropdown.css({
				top: offset.top - modalOffset.top + openDropdown.outerHeight(), // Відстань від верхньої частини контейнера
				maxWidth: $( '.modal.active' ).find( '.dropdown-open' )[ 0 ].clientWidth,
				transform: spaceBelow < dropdownHeight ? 'translate(0, -100%)' : ''
			});
		}
	});

	$( '.modal-box' ).on( 'scroll', function() {
		const dropdown = $( '.modal.active' ).find( '.dropdown-content.show' ); // Отримуємо видимий дропдаун
		if ( dropdown.length ) {
			const openDropdown = dropdown.parent().find( '.dropdown-open' ); // Отримуємо елемент, який відкриває дропдаун
			const offset = openDropdown.offset(); // Отримуємо координати елемента

			// Розрахунок позиції дропдауна відносно контейнера модалки
			const modalContainer = $( '.modal.active' ).find( '.modal-container' ); // Отримуємо контейнер модалки
			const modalOffset = modalContainer.offset(); // Отримуємо координати контейнера
			dropdown.css({
				top: offset.top - modalOffset.top + openDropdown.outerHeight() // Оновлюємо позицію дропдауна
				// left: offset.left - modalOffset.left, // Оновлюємо позицію дропдауна
			});

		}
	});


	// Close the dropdown menu if the user clicks outside of it
	window.onclick = function( event ) {
		if ( isActive ) {
			if ( !event.target.matches( '.dropbtn' ) ) {
				var dropdowns = document.getElementsByClassName( 'dropdown-content' );
				var i;
				$( '.dropdown-select-city' ).closest( '.dropdown' ).removeClass( 'show' );
				$( '.dropdown-select-service' ).closest( '.dropdown' ).removeClass( 'show' );

				for ( i = 0; i < dropdowns.length; i++ ) {
					var openDropdown = dropdowns[ i ];
					if ( openDropdown.classList.contains( 'show' ) ) {
						openDropdown.classList.remove( 'show' );
					}
				}
			}
		}
	};


	$( '.overlay' ).on( 'click', function() {
		$( '.modal.active' ).last().removeClass( 'active' );
		if ( document.querySelector( '.modal.active' ) === null ) {
			$( 'html' ).removeClass( 'overflow-y-hidden' );
		}
	});

	const appHeight = () => {
		const doc = document.documentElement;
		doc.style.setProperty( '--vh', `${ window.innerHeight }px` );
	};
	window.addEventListener( 'resize', appHeight );
	appHeight();

	const isMobile = window.innerWidth < 768;
	if ( isMobile ) {
		const aosElements = document.querySelectorAll( '[data-aos-delay]' );
		aosElements.forEach( ( el ) => {
			el.setAttribute( 'data-aos-delay', 0 );
		});
	}


	initAOS();
	$( '[type="tel"]' ).inputmask( '+38 (999) 999 99 99' );
	const closeButtons = document.querySelectorAll( '.modal-header_close' );
	closeButtons.forEach( function( button ) {
		button.addEventListener( 'click', function( e ) {
			const modal = this.closest( '.modal' );
			if ( modal ) {
				modal.classList.remove( 'active' );
			}
			if ( document.querySelector( '.modal.active' ) === null ) {
				$( 'html' ).removeClass( 'overflow-y-hidden' );
			}

		});
	});

	document.querySelector( '.modal-bottom_button .button-red' ) &&
		document
			.querySelector( '.modal-bottom_button .button-red' )
			.addEventListener( 'click', function( e ) {

				if ( $( '.modal.active' ).attr( 'data-modal' ) === 'phone' ) {
					
					return; //
				}

				let error = false;

				if ( document.querySelector( '.field.firstname input' ).value.length < 1 ) {
					document.querySelector( '.field.firstname span' ).classList.add( 'active' );
					document.querySelector( '.field.firstname' ).classList.add( 'error' );
					error = true;
				} else {
					document.querySelector( '.field.firstname span' ).classList.remove( 'active' );
					document.querySelector( '.field.firstname' ).classList.remove( 'error' );
				}

				if ( !validatePhoneNumber( document.querySelector( '.field.phone input' ).value ) ) {
					document.querySelector( '.field.phone' ).classList.add( 'error' );
					document.querySelector( '.field.phone span' ).classList.add( 'active' );
					error = true;
				} else {
					document.querySelector( '.field.phone' ).classList.remove( 'error' );
					document.querySelector( '.field.phone span' ).classList.remove( 'active' );
				}

				if ( !document.querySelector( '.dropdown-select-city' ).value ) {

					// error
					error = true;
				}

				if ( !document.querySelector( '.dropdown-select-city' ).value ) {

					// error
					error = true;
					$( '.field-dropdown.city' ).find( '.error-message' ).addClass( 'active' );
					$( '.field-dropdown.city' ).addClass( 'error' );
				} else {
					$( '.field-dropdown.city' ).removeClass( 'error' );
					$( '.field-dropdown.city' ).find( '.error-message' ).removeClass( 'active' );
				}

				if ( !document.querySelector( '.dropdown-select-service' ).value ) {

					// error
					error = true;
					$( '.field-dropdown.service' ).find( '.error-message' ).addClass( 'active' );
					$( '.field-dropdown.service' ).addClass( 'error' );
				} else {
					$( '.field-dropdown.service' ).removeClass( 'error' );
					$( '.field-dropdown.service' ).find( '.error-message' ).removeClass( 'active' );
				}

				if ( error === false ) {

					const formData = new FormData();

					formData.append( 'user_name', document.querySelector( '.field.firstname input' ).value );
					formData.append( 'phone', document.querySelector( '.field.phone input' ).value );
					formData.append( 'service_name', currentIdService );

					document.querySelector( '.field.firstname input' ).classList.add( 'disabled' );
					document.querySelector( '.field.phone input' ).classList.add( 'disabled' );
					document.querySelector( '.modal-bottom_button .button-red' ).classList.add( 'disabled' );

					fetch( '/submit-mail.php', {
						method: 'POST',
						body: formData
					})
						.then( response => {
							if ( !response.ok ) {
								throw new Error( 'Network response was not ok' );
							}
							fbq( 'track', 'Lead' );
							window.location = '/thanks.html';
						})
						.then( data => {
						})
						.catch( error => {
							console.error( 'Fetch error:', error );
						})
						.finally( () => {
							document.querySelector( '.field.firstname input' ).classList.remove( 'disabled' );
							document.querySelector( '.field.phone input' ).classList.remove( 'disabled' );
							document.querySelector( '.modal-bottom_button .button-red' ).classList.remove( 'disabled' );
						});
				}

			});


	document
		.querySelectorAll( '.open-modal-phone' )
		.forEach( element => {
			element.addEventListener( 'click', function( e ) {
				$( 'html' ).addClass( 'overflow-y-hidden' );
				document.querySelector( '.field.firstname input' ).classList.remove( 'disabled' );
				document.querySelector( '.field.phone input' ).classList.remove( 'disabled' );
				document.querySelector( '.modal-bottom_button .button-red' ).classList.remove( 'disabled' );
				document.querySelector( '.modal[data-modal="phone"]' ).classList.add( 'active' );
				fbq('track', 'Lead');
			});
		});

	document
		.querySelectorAll( '.open-modal-service' )
		.forEach( element => {
			element.addEventListener( 'click', function( e ) {
				$( 'html' ).addClass( 'overflow-y-hidden' );
				document.querySelector( '.field.firstname input' ).classList.remove( 'disabled' );
				document.querySelector( '.field.phone input' ).classList.remove( 'disabled' );
				document.querySelector( '.modal-bottom_button .button-red' ).classList.remove( 'disabled' );
				document.querySelector( '.modal[data-modal="form"]' ).classList.add( 'active' );
			});
		});


	document
		.querySelectorAll( '.open-modal-policy' )
		.forEach( element => {
			element.addEventListener( 'click', function( e ) {
				$( 'html' ).addClass( 'overflow-y-hidden' );
				document.querySelector( '.modal[data-modal="policy"]' ).classList.add( 'active' );
			});
		});
	var circle = $( '#circle' );
	const maxOffset = 150;
	if ( window.innerWidth >= 1270 ) {
		$( document ).on( 'mousemove', function( e ) {
			const windowWidth = $( window ).width();
			const windowHeight = $( window ).height();
			const offsetX = ( windowWidth / 2 - e.clientX ) / windowWidth * maxOffset;
			const offsetY = ( windowHeight / 2 - e.clientY ) / windowHeight * maxOffset;
			circle.css( 'transform', `translate(${ offsetX }px, ${ offsetY }px)` );
		});
	}
});

